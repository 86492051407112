import React from "react";
import { RouteComponentProps } from "@reach/router";

import SEO from "../components/Seo";
import Category from "../components/FAQ/Category";

const FaqSearch = ({ location }: RouteComponentProps) => {
  const searchValue = location?.href
    ? new URL(location?.href).searchParams.get("value")
    : "";
  return (
    <div>
      <SEO title="FAQ Category" />
      <Category categoryId={""} searchRequest={searchValue || ""} />
    </div>
  );
};

export default FaqSearch;
