import { EmailVerificationParams, EmailVerificationType } from "../types";
import { verifyEmailApi } from "../settings";

import request from "./request";

export const verifyEmailRequest = (
  params: EmailVerificationParams
): Promise<EmailVerificationType> =>
  request(verifyEmailApi, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  });
