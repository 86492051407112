import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";

import SEO from "../components/Seo";
import Category from "../components/FAQ/Category";

interface FaqCategoryProps extends RouteComponentProps {
  id: string;
}

const FaqCategory = ({ id }: FaqCategoryProps): ReactElement => {
  return (
    <div>
      <SEO title="FAQ Category" />
      <Category categoryId={id} />
    </div>
  );
};

export default FaqCategory;
