import React from "react";
import { Router } from "@reach/router";

import Contract from "./contract";
import EmailVerification from "./email-verification";
import PasswordRecovery from "./password-recovery";
import FAQ from "./faq";
import FaqCategory from "./faq-category";
import FaqSearch from "./faq-search";
import Blog from "./blog";
import Article from "./blog-article";

const App = () => {
  return (
    <Router basepath="/app">
      <Contract path="/contract/" />
      <EmailVerification path="/email-verification/" />
      <PasswordRecovery path="/password-recovery/" />
      <FAQ path="/faq/" />
      <FaqCategory path="/faq-category/:id" />
      <FaqSearch path="/faq-search/" />
      <Blog path="/blog/:categoryName" />
      <Article path="/blog-article/:categoryName/:articleId" />
    </Router>
  );
};
export default App;
