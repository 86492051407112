import { useState, useLayoutEffect, useMemo } from "react";
import { verifyEmailRequest } from "../../../services/email";
import { createRequestState } from "../../../utils";
import { EmailVerificationType } from "../../../types";
import { verifyEmailHookReturnType, verifyEmailType } from "../types";

export default function useVerifyEmail({
  token,
}: verifyEmailType): verifyEmailHookReturnType {
  const [emailPromise, setEmailPromise] = useState<
    Promise<EmailVerificationType>
  >();
  const [emailRequest, setEmailRequest] = useState(
    createRequestState<EmailVerificationType>()
  );

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        setEmailRequest({ isPending: true, data: null, error: null });
        const promise = verifyEmailRequest({ token });

        setEmailPromise(promise);

        const data = await promise;
        setEmailRequest({ isPending: false, data, error: null });
      } catch (error) {
        setEmailRequest({ isPending: false, data: null, error });
      }
    };

    fetchData();
  }, [token]);

  return useMemo(() => ({ emailPromise, emailRequest }), [
    emailPromise,
    emailRequest,
  ]);
}
