import React, { ReactElement } from "react";

import Preloader from "../SVG/Preloader";
import useVerifyEmail from "./hooks/useVerifyEmail";
import { verifyEmailType } from "./types";
import ErrorComponent from "./ErrorComponent";
import SuccessComponent from "./SuccessComponent";
import { PreloaderContainer } from "../Contract/Layout/styled";

const VerifyEmail = ({ token }: verifyEmailType): ReactElement => {
  const {
    emailRequest: { isPending, data, error },
  } = useVerifyEmail({ token });

  if (isPending) {
    return (
      <PreloaderContainer>
        <Preloader />
      </PreloaderContainer>
    );
  }

  if (error) {
    return <ErrorComponent />;
  }

  return <SuccessComponent name={data?.customer.firstname as string} />;
};

export default VerifyEmail;
