import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";

import SEO from "../components/Seo";
import VerifyEmail from "../components/VerifyEmail";

interface EmailVerificationProps extends RouteComponentProps {
  token: string;
}

const EmailVerification = ({ token }: EmailVerificationProps): ReactElement => (
  <div>
    <SEO title="Email Verification" />
    <VerifyEmail token={token} />
  </div>
);

export default EmailVerification;
